import React, { useEffect, useRef, useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { type RootState, store, useAppSelector } from '../../store';
import Hyperbeam, { type HyperbeamEmbed } from '@hyperbeam/web';
import { toast } from 'react-toastify';
import { Sentry } from '../../';
import axios, { type ResponseType } from 'axios';
import { getCurrentRoom } from '../../helpers/livekit/utils';
import { ConnectionState } from 'livekit-client';

const isActiveSelector = createSelector(
  (state: RootState) =>
    state.session.currentRoom.metadata?.room_features
      .display_external_link_features,
  (display_external_link_features) => display_external_link_features?.is_active,
);
const linkSelector = createSelector(
  (state: RootState) =>
    state.session.currentRoom.metadata?.room_features
      .display_external_link_features,
  (display_external_link_features) => display_external_link_features?.link,
);

const DisplayExternalLink = () => {
  const link = useAppSelector(linkSelector);
  const isActive = useAppSelector(isActiveSelector);
  const [loaded, setLoaded] = useState<boolean>();
  const cursors = useRef(new Map());
  const usernamesByUserId = useRef(new Map());
  const username = useRef('');
  const [externalLinkContainer, setExternalLinkContainer] = useState<
    HTMLIFrameElement | HTMLDivElement
  >();
  const externalLinkRef = useRef<HTMLIFrameElement | HTMLDivElement>(null);
  const minHeight = 512;
  const maxHeight = 3840;
  const minWidth = 512;
  const maxWidth = 3840;
  const maxArea = 921600;
  useEffect(() => {
    console.info('DisplayExternalLink useEffect called...');
    console.info('DisplayExternalLink useEffect', { isActive });
    const externalLinkRefCurrent = externalLinkRef.current;
    console.info('DisplayExternalLink useEffect externalLinkRefCurrent', {
      externalLinkRefCurrent,
    });
    if (isActive && link) {
      if (externalLinkRefCurrent) {
        console.info(
          'DisplayExternalLink useEffect calling setExternalLinkContainer with externalLinkRefCurrent...',
        );
        setExternalLinkContainer(externalLinkRefCurrent);
        console.info(
          'DisplayExternalLink useEffect setExternalLinkContainer with externalLinkRefCurrent called.',
        );
      } else {
        console.info(
          'DisplayExternalLink useEffect externalLinkRefCurrent is not set.',
        );
      }
    } else {
      if (!isActive) {
        console.info('DisplayExternalLink useEffect isActive is false.');
      }
      if (!link) {
        console.info('DisplayExternalLink useEffect link is not set.');
      }
    }
    console.info('DisplayExternalLink useEffect complete.');
  }, [isActive, link]);

  useEffect(() => {
    console.info('DisplayExternalLink useEffect called...');
    console.info(
      'DisplayExternalLink useEffect externalLinkContainer',
      externalLinkContainer,
    );
    if (externalLinkContainer) {
      console.info(
        'DisplayExternalLink useEffect externalLinkContainer is set',
      );
      const HYPERBEAM_USER_API = axios.create({
        baseURL: 'https://remotenotarez.com/ezmeet2/hyberbeam',
      });
      const sendHypermeamUserAPIRequest = async (
        path: string,
        body: any,
        json_encode = true,
        content_type = 'application/json',
        response_type: ResponseType = 'json',
      ): Promise<{ username: string }> => {
        try {
          if (json_encode) {
            body = JSON.stringify(body);
          }
          const response = await HYPERBEAM_USER_API.post(path, body, {
            headers: {
              'Content-Type': content_type,
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
            },
            responseType: response_type,
          });
          console.info(
            'DisplayExternalLink sendHypermeamUserAPIRequest response',
            response,
          );
          return response.data as { username: string };
        } catch (e) {
          console.error(
            'DisplayExternalLink sendHypermeamUserAPIRequest error',
            e,
          );
          Sentry.captureException(e);
          throw e;
        }
      };
      async function getUsername(userId: string): Promise<string> {
        if (
          usernamesByUserId.current.has(userId) &&
          usernamesByUserId.current.get(userId)
        ) {
          console.info(
            'DisplayExternalLink getUsername found username in usernamesByUserId',
          );
          return usernamesByUserId.current.get(userId);
        }
        console.info(
          'DisplayExternalLink getUsername username not found in usernamesByUserId...',
        );
        try {
          sendHypermeamUserAPIRequest('/user', { userId }).then(
            (responseData) => {
              console.info(
                'DisplayExternalLink sendHypermeamUserAPIRequest responseData',
                responseData,
              );
              if (responseData['username']) {
                usernamesByUserId.current.set(
                  `${userId}`,
                  responseData['username'],
                );
                console.info(
                  'DisplayExternalLink sendHypermeamUserAPIRequest responseData username found and set!!!',
                );
              } else {
                console.info(
                  'DisplayExternalLink sendHypermeamUserAPIRequest responseData username not found',
                );
              }
              // eslint-disable-next-line prettier/prettier
            },
          );
        } catch (err) {
          console.error(
            'DisplayExternalLink sendHypermeamUserAPIRequest unknown error',
            err,
          );
          Sentry.captureException(err);
        }
        console.info(
          'DisplayExternalLink getUsername complete with empty username.',
        );
        return '';
      }

      async function newCursor(userId: string, username: string) {
        console.info('DisplayExternalLink newCursor called...', {
          userId,
          cursors: cursors.current,
        });
        const html = `<div class="cursor" >
          <svg
            className="icon"
            width="192"
            height="32"
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3331 24.4662C14.4454 24.758 14.8616 24.7486 14.9605 24.4519L17.3333 17.3333L24.4519 14.9605C24.7486 14.8616 24.758 14.4454 24.4662 14.3331L8.70001 8.26923C8.43043 8.16555 8.16555 8.43043 8.26923 8.70001L14.3331 24.4662Z"
              fill="red"
            />
            <path
              d="M14.3331 24.4662C14.4454 24.758 14.8616 24.7486 14.9605 24.4519L17.3333 17.3333L24.4519 14.9605C24.7486 14.8616 24.758 14.4454 24.4662 14.3331L8.70001 8.26923C8.43043 8.16555 8.16555 8.43043 8.26923 8.70001L14.3331 24.4662Z"
              stroke="white"
              strokeLinejoin="round"
            />
            <text x="36" y="15" fill="red">${username}</text>
          </svg>
        </div>`;
        // eslint-disable-next-line prettier/prettier
        const elm = new DOMParser().parseFromString(html, 'text/html').body.firstElementChild;
        if (elm) {
          console.info('DisplayExternalLink elm is set', { elm });
          document.body.appendChild(elm);
          const onCloseTimeout = () => {
            cursors.current.delete(userId);
            elm.remove();
          };
          const onFadeTimeout = () => {
            // @ts-expect-error-error
            elm.style.opacity = 0;
          };
          let closeTimeout = setTimeout(onCloseTimeout, 9000);
          let fadeTimeout = setTimeout(onFadeTimeout, 5000);
          const updateCursorFunction = (p: Array<number>) => {
            console.info('DisplayExternalLink update called.', {
              p,
            });
            // @ts-expect-error-error
            elm.style.opacity = 1;
            // @ts-expect-error-error
            elm.style.setProperty(
              'transform',
              `translate(${p[0]}px, ${p[1]}px)`,
            );
            clearTimeout(closeTimeout);
            clearTimeout(fadeTimeout);
            closeTimeout = setTimeout(onCloseTimeout, 9000);
            fadeTimeout = setTimeout(onFadeTimeout, 5000);
          };
          console.info('DisplayExternalLink calling cursors.set...', {
            userId,
          });
          cursors.current.set(userId, updateCursorFunction);
          console.info('DisplayExternalLink cursors.set completed', {
            userId,
          });
        }
        console.info('DisplayExternalLink newCursor complete.');
      }
      // end of newCursor

      let globalHyperbeamEmbed: HyperbeamEmbed | null = null;
      async function loadHyperbeam(
        container: HTMLIFrameElement | HTMLDivElement,
        embedUrl: string,
        webhookUserdata: any,
        adminToken?: string,
      ): Promise<HyperbeamEmbed> {
        console.info('DisplayExternalLink loadHyperbeam called...');
        const options = {
          timeout: 5000, // default = 2000
          videoPaused: false, // default = false
          webhookUserdata,
          async onCursor({ clientX, clientY, userId }) {
            console.info('DisplayExternalLink onCursor event.', {
              clientX,
              clientY,
              userId,
              cursors: cursors.current,
            });
            if (!cursors.current.has(userId)) {
              console.info('DisplayExternalLink cursors.has(userId) is true.');
              const username = await getUsername(`${userId}`);
              if (username) {
                console.info('DisplayExternalLink calling newCursor...');
                await newCursor(userId, username);
                console.info('DisplayExternalLink newCursor completed.');
              } else {
                console.warn('DisplayExternalLink username is empty.');
                console.info('DisplayExternalLink skipped newCursor.');
              }
            } else {
              try {
                const updateCursor = cursors.current.get(userId);
                console.info('DisplayExternalLink calling updateCursor...', {
                  updateCursor,
                });
                updateCursor([clientX, clientY]);
                console.info('DisplayExternalLink updateCursor completed.');
              } catch (err) {
                console.error('DisplayExternalLink onCursor error.', {
                  err,
                });
                Sentry.captureException(err);
              }
            }
          },
          // Callback called when the user disconnects from the virtual browser
          // type is an enum with one of the following values:
          //   "request"  -> virtual browser was manually shut down
          //   "inactive" -> inactive timeout was triggered
          //   "absolute" -> absolute timeout was triggered
          //   "kick"     -> user was kicked from the session
          onDisconnect: async ({ type }) => {
            if (adminToken) {
              switch (type) {
                case 'request':
                  console.info('request disconnect');
                  break;
                case 'inactive':
                  console.info('inactive disconnect');
                  break;
              }
              try {
                console.info(`onDisconnect event ( type = ${type} )`);
                const currentRoom = getCurrentRoom();
                console.info(
                  `onDisconnect currentRoom.state === ${currentRoom.state}`,
                );
                try {
                  // skip the livekit room disconnect if the livekit room is already disconnecting or if the
                  // browser session was closed manually or if the user was kicked from the session
                  if (
                    currentRoom.state === ConnectionState.Connected &&
                    (type === 'inactive' || type === 'absolute')
                  ) {
                    await currentRoom.disconnect();
                  }
                } catch (disconnectErr) {
                  Sentry.captureException(disconnectErr);
                }
              } catch (_err) {
                Sentry.captureException(_err);
              }
            }
          },
          onCloseWarning: ({ type, deadline }) => {
            if (adminToken) {
              switch (type) {
                case 'inactive':
                  if (deadline) {
                    const delayInSeconds = Math.round(deadline.delay / 1000);
                    if (globalHyperbeamEmbed) {
                      toast.info(
                        `Inactive timeout will be reached in ${delayInSeconds} seconds. Sending a ping.`,
                      );
                      globalHyperbeamEmbed.ping();
                    } else {
                      toast.warn(
                        `An absolute timeout in ${delayInSeconds} seconds`,
                        {
                          autoClose: deadline.delay,
                        },
                      );
                    }
                  }
                  break;
                case 'absolute':
                  if (deadline) {
                    const delayInSeconds = Math.round(deadline.delay / 1000);
                    toast.warn(
                      `An absolute timeout in ${delayInSeconds} seconds`,
                      {
                        autoClose: deadline.delay,
                      },
                    );
                  }
                  break;
              }
              Sentry.captureMessage(
                `onCloseWarning event ( type = ${type}, deadline = ${deadline} )`,
              );
            }
          },
        };
        if (adminToken) {
          options['adminToken'] = adminToken;
        } else {
          console.warn(
            'DisplayExternalLink loadHyperbeam adminToken is empty.',
          );
        }
        try {
          try {
            globalHyperbeamEmbed = await Hyperbeam(
              container,
              embedUrl,
              options,
            );
            console.info(
              'DisplayExternalLink hyperbeam embed successfully created.',
              {
                hyperbeamEmbed: globalHyperbeamEmbed,
              },
            );
            setLoaded(true);
            return globalHyperbeamEmbed;
          } catch (hyperbeamError) {
            console.warn(
              'DisplayExternalLink Hyperbeam init error',
              `${hyperbeamError}`,
            );
            throw hyperbeamError;
          }
        } finally {
          console.info('DisplayExternalLink loadHyperbeam completed.');
        }
      }
      const session = store.getState().session;
      const currentUserName = session.currentUser?.name || 'anonymous';
      username.current = `${currentUserName}`;
      const currentUserUserId = session.currentUser?.userId || '';
      const linkFeatures =
        session.currentRoom.metadata?.room_features
          .display_external_link_features;
      if (linkFeatures) {
        const link = linkFeatures.link as string;
        if (link) {
          console.info('DisplayExternalLink calling loadHyperbeam...');
          let adminToken = '';
          let isAdmin = false;
          // @ts-expect-error-error
          const extraData = session.currentRoom.metadata?.extra_data;
          if (extraData && session.currentUser?.metadata?.is_admin) {
            console.info('DisplayExternalLink extraData', extraData);
            try {
              const extraDataParsed = JSON.parse(extraData);
              console.info(
                'DisplayExternalLink extraDataParsed',
                extraDataParsed,
              );
              adminToken = extraDataParsed.hyperbeam_admin_token;
            } catch (parseErr) {
              console.error('DisplayExternalLink extraDataParsed error.');
              Sentry.captureException(parseErr);
            }
          } else {
            if (!extraData) {
              console.warn('DisplayExternalLink extraData is empty.');
            }
            if (!session.currentUser?.metadata?.is_admin) {
              console.warn('DisplayExternalLink not an admin user.');
            }
          }
          console.info('DisplayExternalLink adminToken and link', {
            adminToken,
            link,
            currentUserUserId,
            currentUserName,
          });
          isAdmin = adminToken ? true : false;
          const webhookUserdata = {
            username: currentUserName,
            userId: currentUserUserId,
            isAdmin,
          };
          loadHyperbeam(
            externalLinkContainer,
            link,
            webhookUserdata,
            adminToken,
            // eslint-disable-next-line prettier/prettier
          ).then(
              (hyperbeamEmbed) => {
                console.info('DisplayExternalLink loadHyperbeam completed.');
                try {
                  console.info(
                    `DisplayExternalLink hyperbeamEmbed.userId = ${hyperbeamEmbed.userId}`,
                  );
                  if (adminToken) {
                    const permissions = {
                      // Higher value = higher priority
                      // Users with a higher priority will preempt the control of lower priority users.
                      priority: 10, // default = 0
                      // Number of milliseconds until a user is considered "idle". Once a user is considered
                      // idle, they no longer preempt lower priority users until they interact with the
                      // virtual browser again.
                      idle_timeout: 3000, // default = 0
                      // If control_disabled = true, all control input (mouse movements, keyboard presses)
                      // will be ignored. Note that disabling control does not restrict access to any
                      // APIs that require admin tokens.
                      control_disabled: false, // default = control_disable_default (see REST API)
                    };
                    console.info(
                      'DisplayExternalLink calling hyperbeamEmbed.setPermissions...',
                    );
                    hyperbeamEmbed.setPermissions(
                      hyperbeamEmbed.userId,
                      permissions,
                    );
                    console.info(
                      'DisplayExternalLink hyperbeamEmbed.setPermissions completed.',
                    );
                    const parentContainer = externalLinkContainer.parentElement;
                    console.info(
                      'DisplayExternalLink hyperbeamEmbed parentContainer',
                      parentContainer,
                    );
                    if (parentContainer) {
                      // eslint-disable-next-line prettier/prettier
                      const parentContainerHeight =
                        parentContainer.clientHeight - 2;
                      // eslint-disable-next-line prettier/prettier
                      const parentContainerWidth =
                        parentContainer.clientWidth - 2;
                      const parentContainerArea =
                        parentContainerHeight * parentContainerWidth;
                      console.info(
                        'DisplayExternalLink hyperbeamEmbed parentContainer size',
                        {
                          parentContainerHeight,
                          parentContainerWidth,
                          parentContainerArea,
                        },
                      );
                      if (
                        parentContainerHeight >= minHeight &&
                        parentContainerHeight <= maxHeight &&
                        parentContainerWidth >= minWidth &&
                        parentContainerWidth <= maxWidth &&
                        parentContainerArea < maxArea
                      ) {
                        try {
                          hyperbeamEmbed.resize(
                            parentContainerWidth,
                            parentContainerHeight,
                          );
                        } catch (err) {
                          console.error(
                            `DisplayExternalLink hyperbeamEmbed.resize error (width: ${parentContainerWidth}, height: ${parentContainerHeight})`,
                            err,
                          );
                          Sentry.captureMessage(
                            `${err} ( width: ${parentContainerWidth}, height: ${parentContainerHeight}, parentContainerArea: ${parentContainerArea} )`,
                          );
                          Sentry.captureException(err);
                        }
                      } else {
                        console.debug(
                          'DisplayExternalLink hyperbeamEmbed initial size is not valid.',
                          {
                            parentContainerHeight,
                            parentContainerWidth,
                            parentContainerArea,
                          },
                        );
                      }
                      const ro = new ResizeObserver((entries) => {
                        for (const entry of entries) {
                          console.debug(
                            'DisplayExternalLink ResizeObserver entry',
                            { entry },
                          );
                          // eslint-disable-next-line prettier/prettier
                          const entryContentRectWidth = Math.round(entry.contentRect.width) - 2;
                          // eslint-disable-next-line prettier/prettier
                          const entryContentRectHeight = Math.round(entry.contentRect.height) - 2;
                          // eslint-disable-next-line prettier/prettier
                          const entryContentRectArea = entryContentRectHeight * entryContentRectWidth;
                          console.debug(
                            'DisplayExternalLink ResizeObserver contentRect',
                            {
                              entryContentRectWidth,
                              entryContentRectHeight,
                              entryContentRectArea,
                            },
                          );
                          if (
                            hyperbeamEmbed.height !== entryContentRectHeight ||
                            hyperbeamEmbed.width !== entryContentRectWidth
                          ) {
                            if (
                              entryContentRectHeight >= minHeight &&
                              entryContentRectHeight <= maxHeight &&
                              entryContentRectWidth >= minWidth &&
                              entryContentRectWidth <= maxWidth &&
                              entryContentRectArea <= maxArea
                            ) {
                              try {
                                console.debug(
                                  'DisplayExternalLink ResizeObserver calling hyperbeamEmbed.resize...',
                                );
                                hyperbeamEmbed.resize(
                                  entryContentRectWidth,
                                  entryContentRectHeight,
                                );
                                console.debug(
                                  'DisplayExternalLink ResizeObserver hyperbeamEmbed.resize called.',
                                );
                              } catch (err) {
                                console.error(
                                  `DisplayExternalLink hyperbeamEmbed.resize error (width: ${parentContainerWidth}, height: ${parentContainerHeight})`,
                                  err,
                                );
                                Sentry.captureMessage(
                                  `${err} ( width: ${parentContainerWidth}, height: ${parentContainerHeight}, parentContainerArea: ${parentContainerArea} )`,
                                );
                                Sentry.captureException(err);
                              }
                            } else {
                              console.debug(
                                'DisplayExternalLink hyperbeamEmbed size is not valid.',
                                {
                                  entryContentRectHeight,
                                  entryContentRectWidth,
                                  entryContentRectArea,
                                },
                              );
                            }
                          }
                        }
                      });
                      ro.observe(parentContainer);
                    } else {
                      console.warn(
                        'DisplayExternalLink hyperbeamEmbed parentContainer is empty.',
                      );
                    }
                  } else {
                    console.warn(
                      'DisplayExternalLink skipping hyperbeamEmbed.setPermissions.',
                    );
                  }
                } catch (err) {
                  console.warn(
                    'DisplayExternalLink post loadHyperbeam error.',
                    err,
                  );
                  Sentry.captureException(err);
                }
              },
              // eslint-disable-next-line prettier/prettier
            ).catch(
              // eslint-disable-next-line prettier/prettier
              (err) => {
                console.warn('DisplayExternalLink loadHyperbeam failed.', err);
                Sentry.captureException(err);
              },
            );
        }
      }
    } else {
      console.info(
        'DisplayExternalLink useEffect externalLinkContainer is not set.',
      );
    }
  }, [externalLinkContainer]);

  const render = () => {
    if (!link || link === '') {
      return null;
    }
    return (
      <div ref={externalLinkRef} className="external-display-link-wrapper">
        {!loaded ? (
          <div className="loading absolute left-[50%] top-[40%] flex justify-center">
            <div className="lds-ripple">
              <div className="border-secondaryColor"></div>
              <div className="border-secondaryColor"></div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return <>{isActive ? render() : null}</>;
};

export default DisplayExternalLink;
